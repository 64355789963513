'use client';

import { useRouter } from 'next/navigation';
import Link from "next/link";
import DrupalImage from "@/components/atoms/DrupalImage";
import { FragmentImageFragment, ImageStyleAvailable } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";
import Icon from "@/components/atoms/Icon.tsx";
import { mapTonSurTonColor } from "@/helpers/colors";
import ListClickTracking from "@/components/tracking/ListClickTracking.tsx";
import VerticalListVisibilityTracker from "@/components/tracking/VerticalListVisibilityTracker.tsx";
import React from "react";

interface TeaserContentProps {
  bgColor?: string;
  description?: {
    processed: string;
  };
  link?: {
    internal: boolean;
    title: string;
    url: string;
  };
  media?: FragmentImageFragment;
  play_button?: boolean;
  thema?: any[];
  title: string;
  position: number;
  listPosition: number;
  count: number;
  listCount: number;
  parentTitle: string;
  parentType: string;
  parentID: string;
  listType: string;
  linkType: string;
}

function convertDescriptionContent(htmlString: string): string {
  // Remove heading tags and their attributes
  let convertedHtml = htmlString.replace(/<h[2-6][^>]*>(.*?)<\/h[2-6]>/gi, '$1');

  // Remove strong tags and their attributes
  convertedHtml = convertedHtml.replace(/<strong[^>]*>(.*?)<\/strong>/gi, '$1');

  // Wrap the converted content in a <p> tag
  return `<p>${convertedHtml}</p>`;
}

const TeaserContent = ({
                         title,
                         description,
                         thema,
                         media,
                         link,
                         play_button,
                         bgColor,
                         count,
                         listCount,
                         position,
                         listPosition,
                         parentType,
                         parentID,
                         parentTitle,
                         listType,
                         linkType
                       }: TeaserContentProps) => {
  const router = useRouter();

  const convertedDescription = description
    ? convertDescriptionContent(description.processed)
    : "";
  const image = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.LandscapeRatio32)
    : null;
  const mobileImage = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.PortraitRatio23)
    : null;

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent the default link behavior

    ListClickTracking({
      listTitle: parentTitle,
      listType: listType,
      componentName: parentType,
      componentID: parentID,
      componentCount: count,
      componentPosition: position,
      imageSource: image?.url ?? "",
      targetUrl: link?.url || "",
      targetPageId: link?.url || "",
      targetPageName: link?.url || "",
      listElementTitle: title,
      listElementPosition: listPosition,
      listElementCount: listCount,
      linkTitle: link?.title || title,
      linkType: linkType,
    });

    // Navigate to the URL after tracking
    if (link?.url) {
      if (link.internal) {
        router.push(link.url);
      } else {
        window.open(link.url, '_blank', 'noopener,noreferrer');
      }
    }
  };

  return (
    <div
      id={`list-item-${parentID}-${listPosition}`}
      className={cn("teaser-content relative flex flex-col items-stretch group")}
    >
      <VerticalListVisibilityTracker
        listTitle={parentTitle}
        listType={parentType}
        componentID={parentID}
        componentName={parentType}
        componentPosition={position}
        componentCount={count}
        listElementTitle={title}
        listElementPosition={listPosition}
        listElementCount={listCount}
        imageSource={image?.url ?? ""}
        targetId={`list-item-${parentID}-${listPosition}`}
      />

      <div className="relative w-full h-[220px] lg:h-[300px] overflow-hidden flex shrink-0 items-center justify-center rounded-lg">
        {image && (
          <>
            <DrupalImage
              url={mobileImage.url}
              alt={media.alt}
              width={mobileImage.width}
              height={mobileImage.height}
              className="w-full transition-transform duration-300 xl:hidden xl:group-hover:scale-105 h-full object-cover"
            />
            <DrupalImage
              url={image.url}
              alt={media.alt}
              width={image.width}
              height={image.height}
              sizes="33vw"
              className="hidden w-full transition-transform duration-300 xl:block xl:group-hover:scale-105 h-full object-cover"
            />
            {play_button && (
              <span className="w-12 h-12 rounded-full border-red border opacity-0 text-white bg-red scale-110 group-hover:opacity-100 inline-flex items-center justify-center group-hover:scale-100  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 transition-all duration-500">
                <Icon name="play-thin" className=" h-4 w-4" />
              </span>
            )}
          </>
        )}
      </div>
      <div className="flex flex-1 mt-2 lg:mt-3">
        <div className="w-full h-full flex flex-col">
          {thema && (
            <div className="mb-1 flex gap-1 flex-row font-semibold">
              {thema.map((themaItem, index) => (
                <span key={themaItem.id} className="flex gap-1 flex-row teaser-meta">
                  {index !== 0 && (
                    <span
                      className={cn(
                        "text-sm  teaser-meta__divider",
                        bgColor
                          ? `${mapTonSurTonColor[bgColor].light.text} `
                          : "text-yellow-700/20"
                      )}
                    >
                      {" "}
                      |{" "}
                    </span>
                  )}
                  <span
                    className={cn(
                      "text-sm teaser-meta__label\"",
                      bgColor
                        ? `${mapTonSurTonColor[bgColor].light.text} `
                        : "text-yellow-700"
                    )}
                  >
                    {themaItem.name}
                  </span>
                </span>
              ))}
            </div>
          )}
          {link && title ? (
            <Link
              href={link.url}
              className={cn("stretched-link mr-auto", description && "mb-4")}
              target={link.internal ? "_self" : "_blank"}
              onClick={handleLinkClick}
            >
              <span
                className={cn(
                  "link no-underline flex pb-1 font-semibold lg:text-lg !leading-none",
                  description && "mb-1"
                )}
              >
                {title}
              </span>
              {description && (
                <div
                  className="prose prose-sm prose-teasers !leading-tight text-grey/70 line-clamp-2"
                  dangerouslySetInnerHTML={{ __html: convertedDescription }}
                />
              )}
            </Link>
          ) : (
            <span className="pb-1 font-semibold lg:text-lg !leading-none">
              {title}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeaserContent;