import {forwardRef, Ref} from "react";
import Link, {type LinkProps} from "next/link";

import Icon from "@/components/atoms/Icon";
import {cn} from "@/helpers/className";
interface BaseProps {
  arrow?: boolean;
  as?: "button" | "link" | "externalLink";
  children: React.ReactNode;
  className?: string;
  color?: "default" | "white" | "vrt_max" | "vrt_nws" | "sporza" | "ghost" | "emerald" | "emerald_light" | "marine" | "marine_light" | "amber" | "amber_light" | "magenta" | "magenta_light" | "tangerine" | "tangerine_light";
  hasLoader?: boolean;
  isLoading?: boolean;
  target?: string;
}

type ButtonAsButton = BaseProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
  as?: "button";
};

type ButtonAsLink = BaseProps &
  Omit<LinkProps, keyof BaseProps> & {
  as: "link";
};

type ButtonAsExternal = BaseProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps> & {
  as: "externalLink";
};

export type ButtonProps = ButtonAsButton | ButtonAsExternal | ButtonAsLink;

/**
 * Polymorphic Button component
 * `as` can be either:
 * * `button`: will render a `<button>` tag
 * * `link`: will render a NextJS `<Link>` component
 * * `externalLink`: will render a `<a>` tag
 */
const Button = forwardRef<unknown, ButtonProps>(
  (
    {
      arrow = false,
      color = "",
      isLoading,
      className,
      children,
      hasLoader,
      target,
      ...props
    },
    ref,
  ) => {
    const colorClasses = {
      white: "btn-white",
      default: "btn-primary",
      ghost: "btn-ghost",
      vrt_max: "btn-max",
      vrt_nws: "btn-nws",
      sporza: "btn-sporza",
      emerald: "btn-emerald",
      emerald_light: "btn-emerald-light",
      marine: "btn-marine",
      marine_light: "btn-marine-light",
      amber: "btn-amber",
      amber_light: "btn-amber-light",
      magenta: "btn-magenta",
      magenta_light: "btn-magenta-light",
      tangerine: "btn-tangerine",
      tangerine_light: "btn-tangerine-light",
    }

    const classNames = cn(
      "btn",
      colorClasses[color],
      isLoading && "is-loading",
      className
    );

    const {as} = props;
    delete props.as;

    const buttonChildren = (
      <>
        {children}
        {color === "vrt_max" && (
          <Icon name="vrt_max" ariaLabel={"VRT MAX"} className="h-4 w-14 lg:h-6 lg:w-16" />
        )}
        {color === "vrt_nws" && (
          <Icon name="vrt_nws" ariaLabel={"VRT NWS"} className="h-4 w-14 lg:h-6 lg:w-16" />
        )}

        {color === "sporza" && (
            <Icon name="sporza" ariaLabel={"Sporza"} className="h-4 w-14 lg:h-4 lg:w-16 translate-y-[4px]" />
        )}
        {(arrow && !isLoading) && (
          <span className="btn__arrow">
            <Icon name="arrow-right" className="h-4 w-4" />
          </span>
        )}
        {hasLoader && (
          <span className="btn__loader">
            <Icon name="loader" className="h-4 w-4 animate-spin"/>
          </span>
        )}
      </>
    );

    if (as === "link") {
      return (
        <Link
          className={classNames}
          {...props}
          target={target}
          ref={ref as Ref<HTMLAnchorElement>}
        >
          {buttonChildren}
        </Link>
      );
    }

    if (as === "externalLink") {
      return (
        <a
          className={classNames}
          target="_blank"
          rel="noopener noreferrer"
          ref={ref as Ref<HTMLAnchorElement>}
          {...props}
        >
          {buttonChildren}
        </a>
      );
    }

    return (
      <button
        ref={ref as Ref<HTMLButtonElement>}
        className={classNames}
        {...props}
      >
        {buttonChildren}
      </button>
    );
  },
);

export default Button;
